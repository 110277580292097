// https://github.com/mzabriskie/axios
// Promise based HTTP client for the browser and node.js
//
// axios is a fully-fledged XMLHttpRequest library with a promise api,
// interceptors, cancellations, json transformation. It's a common pairing with
// react and provides a more modern interface / functionality than jquery's ajax.
// A singleton instance is exported here for use within the application. Configure
// as necessary.
import axios from "axios";
import csrfToken from "common/csrf-token";

const instance = axios.create();

instance.defaults.headers.common["X-CSRF-Token"] = csrfToken;
instance.defaults.headers.common.Accept = "application/json";

export default instance;
