import request from "common/request";

const getFormActionUrl = async e => {
  try {
    const form = e.target.closest("form");
    const response = await request.get(form.dataset.loginEndpoint, {
      params: { email: e.target.value }
    });

    form.setAttribute("action", response.data.loginUrl);
  } catch ({ response }) {
    console.error(response.data.error);
  }
};

const enableDynamicLoginForm = () => {
  document.querySelectorAll("input[type=email]").forEach(formEl => {
    formEl.addEventListener("blur", getFormActionUrl);
  });
};

export default enableDynamicLoginForm;
